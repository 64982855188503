export const detectionStatus = [
    {
        name: 'request',
        description: "의뢰",
        value: 1,
        explanation: 'request_explanation',
        class: '',
    },
    {
        name: 'de_review',
        description: "검토",
        value: 2,
        explanation: 'de_review_explanation',
        class: '',
    },
    {
        name: 'de_ready',
        description: "준비",
        value: 3,
        explanation: 'de_ready_explanation',
        class: '',
    },
    {
        name: 'de_process',
        description: "처리",
        value: 4,
        explanation: 'de_process_explanation',
        class: '',
    },
    {
        name: 'de_civil_criminal',
        description: "민형사",
        value: 5,
        explanation: 'de_civil_criminal_explanation',
        class: '',
    },
    {
        name: 'de_closing',
        description: "종결",
        value: 6,
        explanation: 'de_closing_explanation',
        class: '',
    },
    {
        name: 'cancel_s_request',
        description: "의뢰 취소",
        value: 0,
        explanation: 'cancel_s_request_explanation',
        class: 'red_text',
    },
];

export const detectionDomainStatus = [
    {
        value: 0,
        name: 'permission',
        noChange: false,
        class: 'permission',
        description: '사용허가',
    },{
        value: 1,
        name: 'no_myImg',
        noChange: false,
        class: 'noImg',
        description: '내 이미지 아님',
    },{
        value: 2,
        name: 'ignore',
        noChange: false,
        class: 'ignore',
        description: '무시',
    },{
        value: 3,
        name: 'hold',
        noChange: false,
        class: 'hold',
        description: '보류',
    },{
        value: 4,
        name: 'request_ing',
        noChange: true,
        class: 'request',
        description: '의뢰중',
    },
];

